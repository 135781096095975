import Script from 'next/script'

function Gtag() {
  return (
    <div className="container">
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-NSDNKG86G1" strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-NSDNKG86G1', {'anonymize_ip': true});
        `}
      </Script>
    </div>
  )
}

export default Gtag