import Gtag from '../utils/gtag';
import texgyrefont from '../utils/fonts';
import VideoLazyLoad from '../utils/video-lazyload';

import 'bootstrap/dist/css/bootstrap.min.css'

import '../styles/globals.css'

import Layout from '../components/Layout/Layout';

import type { AppProps } from 'next/app'

export default function App({ Component, pageProps }: AppProps) {

return <main className={texgyrefont.variable}>
    <Gtag />
    <VideoLazyLoad />
    <Layout>
      <Component {...pageProps} />
    </Layout>
  </main>
}
