
import React from "react";
import { Container } from "reactstrap";
import classes from "./footer.module.css";

const Footer = () => {
    return <footer className={`${classes.footer}`}>
        ©2023 Molotuff ApS. All Rights Reserved
    </footer>
}

export default Footer;