
import React, {useState, useEffect } from "react";
import { Container } from "reactstrap";
import classes from "./header.module.css";
import Link from "next/link";

const NAV__LINK =[
    {
        path:'#works',
        display: 'Works'
    },
    {
        path:'#services',
        display: 'Services'
    },
    {
        path:'#about',
        display: 'About'
    },
    {
        path:'#connect',
        display: 'Connect'
    }
];
  
function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
    useEffect(() => {
      let lastScrollY = window.pageYOffset;
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 2 || scrollY - lastScrollY < -2)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
    return scrollDirection;
};

const Header = (props) => {
    const [toggled, setToggled] = useState('nav_mobile_hidden', '');
    
    useEffect(() => {
        toggled === '' ? document.body.classList.add('disable_scroll') : document.body.classList.remove('disable_scroll');
    });

    function toggleNav(e) {
        e.stopPropagation();
        if (window.innerWidth > 991) return;
        toggled === '' ? setToggled('nav_mobile_hidden') : setToggled('');
    }

    const scrollDirection = useScrollDirection();

    return <header className={`${toggled} ${classes.header} header ${ scrollDirection === "down" ? "hide" : "show"}`}>
        {/* ============ header wordmark ============= */}
        <div className={`${classes.nav__wordmark}`}>
            <Link href="/"><h1>molotuff</h1></Link>
        </div>
        <button className={`${classes.nav__toggle}`} onClick={(e) => toggleNav(e)} />
        <Container className={`${classes.nav__container}`}>
            <div className={`${classes.nav__wrapper}`}>
                {/* ============ nav menu ==================*/}
                <div className={`${classes.navigation}`}>
                    <div className={`${classes.nav__menu}`}>
                        {
                            NAV__LINK.map((item,index) => (
                                <Link href={item.path} key={index} onClick={(e) => toggleNav(e)}>
                                    {item.display}
                                </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    </header>
}

export default Header;